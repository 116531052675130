import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {
  Heading,
  SubHeading,
  Rel,
  Section,
  Text,
  Panel,
  Container,
  FlexLayout,
  Icon,
  Label,
} from '../../elements';
import Back from '../../components/Back';
import Card, { CardContainer } from '../../components/Card';
import { ItunesBadge, GooglePlayBadge } from '../../components/AppBadges';
import AppContainer from '../../components/AppContainer';
import IconList from '../../components/IconList';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import Content from '../../components/Content';

const Asset = styled.div`
  justify-content: center;
  display: flex;
  img {
    max-width: 100%;
    height: auto;
    max-height: calc(100px - 2rem);
  }
`;

const AssetContainer = styled(CardContainer)`
  width: 100%;
  max-width: 300px;
  margin-right: 2.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  color: inherit;
  @media screen and (max-width: 420px) {
    max-width: 100%;
    margin-right: 0;
    flex-basis: 100%;
    div,
    footer {
      padding: 0rem;
    }
  }
  border: 1px solid #d6dde3;
  background-color: #f3f8fa;
  &:hover {
    border: 1px solid #d6dde3;
    background-color: #fafafa;
  }
  header {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9edaf5;
    padding: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1rem;
    background-color: #004b6d;
  }
`;

const ContentBox = styled.div`
  padding: 0rem;

  /* border-top: 1px solid ${(props) => props.theme.light}; */
`;


const Color = styled.span`
  padding: 2rem;
  background-color: ${(props) => props.color};
  font-weight: 200;
  letter-spacing: -1px;
  color: white;
  p {
    margin: 0;
    font-size: 1.5rem;
  }
`;

const Title = styled.h3`
  font-size: 1.45rem;
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
  padding-bottom: 0.35rem;
  /* color: ${(props) => props.theme.dark}; */
  margin-bottom: 1rem;
`;

const Desc = styled.p`
  font-size: 85%;
  margin-top: 0;
`;

const AssetItem = styled(Text)`
  margin-bottom: 0;
  font-size: 0.94rem;
`;
const SubSection = styled.p`
  /* border-bottom:  ${(props) => `1px solid ${props.theme.bluegrey}`}; */
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const AssetsContainer = styled(FlexLayout)`
  /* @media screen and (max-width: 768px) {
    margin-left: -2rem;
    margin-right: -2rem;
  } */
`;

const AssetCard = ({ item, component: Component }) => (
  <AssetContainer>
  
      <header>
        {Component && <Component component={item.component} image={item.image ? item.image : {}} />}
      </header>
    <Title>{item.label}</Title>
    {item.text && <Desc dangerouslySetInnerHTML={{ __html: item.text.text }} />}
    {item.infoList && (
      <ContentBox>
        <SubSection>
          <Icon>photo_library</Icon> Assets
        </SubSection>
        {item.infoList.map((info, i) => (
          <AssetItem key={i} dangerouslySetInnerHTML={{ __html: info }} />
        ))}
      </ContentBox>
    )}
  </AssetContainer>
);

const AssetBox = ({ component, image }) => (
  <Asset type={component}>{image.fixed && <img src={image.fixed.src} />}</Asset>
);

const ColourBox = ({ item }) => (
  <AssetContainer>
    <Title>{item.label}</Title>
    <Color color={item.extraInfo || 'white'}>
      {item.text && <Text dangerouslySetInnerHTML={{ __html: item.text.text }} />}
    </Color>
  </AssetContainer>
);


const Branding = ({ data }) => {
  const { contentfulPage: page } = data;
  return (
    <AppContainer pageTitle={page.title}>
      <Panel id="content">
        <Back to="/" />
        <Heading ariaRole="heading">{page.heading}</Heading>

        {page.sections &&
          page.sections.map((section) => (
            <Section key={section.id}>
              <SubHeading>{section.heading}</SubHeading>
              <p>{section.subHeading}</p>
              {section.content && (
                <Content content={section.content.childContentfulRichText.html} />
              )}
              {section.items && (
                <AssetsContainer>
                  {section.items.map((item, i) =>
                    item.component === 'ColourBox' ? (
                      <ColourBox item={item} />
                    ) : (
                      <AssetCard key={i} item={item} component={AssetBox} />
                    )
                  )}
                </AssetsContainer>
              )}
            </Section>
          ))}
      </Panel>
    </AppContainer>
  );
};

export const ajnBrandingPageQuery = graphql`
  query AjnBrandingPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      image {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      sections {
        id
        heading
        subHeading
        content {
          content
          childContentfulRichText {
            html
          }
        }
        items {
          label
          image {
            fixed(width: 200) {
              ...GatsbyContentfulFixed
            }
          }
          text {
            text
          }
          infoList
          extraInfo
          component
        }
      }
    }
  }
`;

export default Branding;
